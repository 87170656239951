<template>
    <div class="memberInfoModify">
       <div class="scroll-box">
            <div class="tr-li card-tr">
                <div class="lable-txt">卡号：</div>
                <div class="from-input">
                    <div class="card-no input-box"><input-pattern keyBoard pattern="code" placeholder="请输入卡号或读卡" v-model="cardNo" @keyup.enter="selectReadCard()" :maxlength="20"/><i class="iconfont icon-sousuo" @click="selectReadCard()"></i></div> 
                    <div class="read-card" @click="readCard()">读卡</div>    
                </div>
            </div>
            <div class="info-box">
                <div class="title">客户资料信息</div>
                <div class="tr-li">
                    <div class="lable-txt">会员等级：</div>
                    <div class="from-input"><div class="input-box"><input type="text" :value="memberInfo?.Bestech_Card_KindName" readonly/></div></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">客户姓名：</div>
                    <div class="from-input"><div class="input-box"><input type="text" v-board  v-model="name" :readonly="!memberInfo"/></div></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">生日：</div>
                    <div class="from-input">
                        <div class="input-box">
                            <el-date-picker class="from-date"
                            :clearable="false"
                            :editable="false"
                            v-model="birthday"
                            @change="(e)=>{birthday=birthday.Format('yyyy-MM-dd');}"
                            type="date"
                            placeholder=""
                            :readonly="!memberInfo"
                            :default-value="(new Date())">
                            </el-date-picker>
                        </div>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">性别：</div>
                    <div class="from-input">
                        <div class="input-box">
                            <el-select v-model="sex" :disabled="!memberInfo">
                                <el-option label="男" :value="0"> </el-option>
                                <el-option label="女" :value="1"> </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="td-5">
                        <div class="lable-txt">原手机号：</div>
                        <div class="from-input"><input type="text" :value="this.memberInfo?.Mobile"  readonly/></div>
                    </div>
                    <div class="td-5">
                        <div class="from-input">
                            <div class="code"><input-pattern keyBoard pattern="code" :maxlength='6' placeholder="请输入验证码"  v-model="oldCode.verifyCode" :readonly="!memberInfo"/></div>
                            <div class="send-code loop-code" v-if="oldCode.isCodeTime">{{oldCode.codeTime}}秒</div>    
                            <div class="send-code" @click="readCode(true)" v-else>验证码</div> 
                        </div>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="td-5">
                        <div class="lable-txt">新手机号：</div>
                        <div class="from-input"><input-pattern keyBoard pattern="phone" placeholder="请输入手机号码" v-model="newCode.mobile" :maxlength="11" :readonly="!memberInfo"/></div>
                    </div>
                    <div class="td-5">
                        <div class="from-input">
                            <div class="code"><input-pattern keyBoard pattern="code" :maxlength='6'  placeholder="请输入验证码" v-model="newCode.verifyCode" :readonly="!memberInfo"/></div>
                            <div class="send-code loop-code" v-if="newCode.isCodeTime">{{newCode.codeTime}}秒</div>    
                            <div class="send-code" @click="readCode()" v-else>验证码</div> 
                        </div>                    
                    </div>
                </div>
                <div class="title">卡资产信息</div>
                <div class="tr-li">
                    <div class="lable-txt">余额：</div>
                    <div class="from-input">
                        <div class="input-box"><input class="text-right" type="text" :value="memberInfo?.Card_Money" readonly/>
                        <p class="describe" :style="memberInfo?'':'visibility: hidden'">其中赠送金额{{memberInfo?.Gift_Money}}元</p></div>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">积分：</div>
                    <div class="from-input">
                        <div class="input-box"><input class="text-right" type="text" :value="memberInfo?.Card_PilePoint" readonly/>
                        <p class="describe"></p></div>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">券：</div>
                    <div class="from-input ticket" @click="ticketClick()">
                        <div class="input-box"><input class="text-right" :value="(memberInfo?.Coupons?.length||'0')+'张'" type="text"  readonly/><i class="iconfont">></i>
                        <p class="describe"></p></div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="footer-box">
            <button class="btn" @click="back()">返回</button>
            <button class="btn selected" @click="memberInfoModify()">确定修改</button>
        </div>
        <!-- 券列表 -->
        <modal-load :isShow="ticketShow">
            <ticket-model :isShow="ticketShow" @closeModel="ticketShow=false" :Coupons="memberInfo?.Coupons"></ticket-model>
        </modal-load> 
        <!-- 选择卡信息 -->
        <modal-load :isShow="cardMemberShow">
            <select-card-member-info :isShow="cardMemberShow" @closeModel="cardMemberShow=false" :data="cardMemberList" @confirm="selectCardInfo"></select-card-member-info>
        </modal-load>
    </div>
</template>

<script>
import { ticketModel,selectCardMemberInfo } from '../model'

export default {
    name:'memberInfoModify',
    components:{ticketModel,selectCardMemberInfo},
    data(){
        return {
            /**卡号 */
            cardNo:'',
            /**卡id */
            card_AutoID:'',
             /**会员信息 */
            memberInfo:undefined,
            /**券列表 */
            ticketShow:false,
            /**选择卡信息 */
            cardMemberShow:false,
            /**多个卡信息 */
            cardMemberList:[],
            /**生日 */
            birthday:'',
            /**客户姓名 */
            name:'',
            /**性别 */
            sex:'',
            oldCode:{
                verifyCode:'',
                /**倒计时 */
                codeTime:60,
                time:undefined,
                /**验证码是否倒计时 */
                isCodeTime:false
            },
            newCode:{
                mobile:'',
                verifyCode:'',
                /**倒计时 */
                codeTime:60,
                time:undefined,
                /**验证码是否倒计时 */
                isCodeTime:false
            }
        }
    },
    watch:{
        'newCode.mobile':function(val){
            this.newCode.mobile=val.replace(/[^0-9]/g,'');
        },
        cardNo(){
           this.empty();
        }
    },
    methods:{
        /**退出 */
        back(){
            this.$emit("close");
        },
         /*刷新当前页面 */
        reload() {
            this.$emit("reload");
        },
        ticketClick(){
            if(this.memberInfo){
                this.ticketShow = true;
            }
        },
        /**点击查询图标读卡   m1卡 Card_AutoID 卡id,Card_SN 内卡号 */
        selectReadCard(Card_AutoID,Card_SN){
            if(!this.cardNo && !Card_SN){
                this.$message.warning('请填写会员卡号')
                return
            }
            const loading = this.$loading({
                text: "读取会员信息中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",{
                User_ID:this.userInfo?.User_ID,
                DynamicNo:this.cardNo,
                Card_AutoID:Card_AutoID,
                Card_SN:Card_SN
            }).then((data)=>{
                loading.close();
                this.empty();
                if(data.ResponseHeader.ResultCode==0){
                    if(data.ResponseBody.length>1){
                        this.cardMemberList=data.ResponseBody;
                        this.cardMemberShow=true;
                    }else{
                        this.selectCardInfo(data.ResponseBody[0])
                    }
                }else{
                    this.memberInfo=null;
                    this.$message.error("会员信息读取失败："+data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.empty();
                this.$message.error('会员信息读取失败：'+e);
                console.log('会员信息读取失败：'+e);
            })
        },
        /**点击 读卡 */
        readCard(){
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.card_AutoID=d.data.cardID;
                        this.cardNo=d.data.cardNo;
                        this.selectReadCard(d.data.cardID,d.data.snr);
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        /**多个卡信息时选择一个 */
        selectCardInfo(data){
            this.cardMemberShow=false;
            this.cardNo=data.Card_No;
            this.$nextTick(()=>{
                this.memberInfo = data;
                this.card_AutoID = this.memberInfo?.Bestech_Card_AutoID;
                this.birthday=this.memberInfo?.Birthday;
                this.name=this.memberInfo?.MemberName;
                this.sex=this.memberInfo?.Gender;
            })
        },
        /**倒计时 */
        doLoop(data){
            data.codeTime=60;
            data.isCodeTime=true;
            if(data.time) clearInterval(data.time);
            data.time=setInterval(() => {
                if(data.codeTime>0){
                    data.codeTime--;
                }else{
                    clearInterval(data.time);
                    data.isCodeTime=false;
                }
            }, 1000);
        },
        /**发送验证码 */
        readCode(isOld){
            if(!this.memberInfo) return;
            let data={};
            let mobile='';
            if(isOld){
                data=this.oldCode;
                mobile=this.memberInfo?.Mobile;
            }else{
                data=this.newCode;
                mobile=data.mobile;
                const  reg = /^1[0-9]{10}$/
                if(data.mobile.length<10 || !reg.test(data.mobile)){
                    this.$message.error('请输入正确的手机号');
                    return
                }
            }
            const loading = this.$loading({
                text: "发送验证码中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.SendMobileSMSCode",{
                App_Type:3,//1：卡绑定   2：实体卡开卡验证码  3：修改手机号验证   4：卡解绑验证
                Mobile:mobile,
                Verify_Type:0,
                Verify_Code:''
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.doLoop(data);
                    this.$message.success("验证码发送成功,请注意查收");
                }else{
                    this.$message.error("验证码发送失败："+d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('验证码发送失败：'+e);
                console.log('验证码发送失败：'+e);
            })
        },
        /**会员信息修改 */
        memberInfoModify(){
            if(!this.cardNo){
                 this.$message.warning('请先输入卡号');
                 return
            }
            if(!this.memberInfo){
                 this.$message.warning('请先获取会员卡数据');
                 return
            }

            let data={
                User_ID:userInfo?.User_ID,
                Card_No:this.cardNo,//外卡号
                Card_AutoID:this.memberInfo?.Bestech_Card_AutoID,//卡主键
                Operator_Name:userInfo?.Login_Name//操作员
            }
            let isModified=false;//是否有修改过
            if(this.name!=this.memberInfo.MemberName){
                data.Member_Name=this.name;//会员姓名
                isModified=true;
            }
            if(this.birthday!=this.memberInfo.Birthday){
                data.Birthday=this.birthday;//生日
                isModified=true;
            }
            if(this.sex!=this.memberInfo.Gender){
                data.Gender=this.sex;//性别
                isModified=true;
            }
            if(this.name!=this.memberInfo.MemberName){
                data.Member_Name=this.name;//会员姓名
                isModified=true;
            }
            if(this.memberInfo?.Mobile && this.newCode.mobile==this.memberInfo?.Mobile){
                this.$message.warning('新手机号不能与旧手机号相同');
                return
            }

            if(this.newCode.mobile){
                if(this.memberInfo?.Mobile){
                    if(!this.oldCode.verifyCode){
                        this.$message.warning('请输入旧手机号验证码');
                        return;
                    }
                    data.OldMobile=this.memberInfo?.Mobile;//旧手机号
                    data.OldMobileCheckCode=this.oldCode.verifyCode;//旧手机验证码
                }
                if(!this.newCode.verifyCode){
                    this.$message.warning('请输入新手机号验证码');
                    return;
                }
                data.IsUpdateMobile=1;// 是否修改手机号 0否 1是
                data.NewMobile=this.newCode.mobile;//新手机号
                data.NewMobileCheckCode=this.newCode.verifyCode;//新手机验证码
                isModified=true;
            }

            if(!isModified){
                 this.$message.warning('没有修改数据，请先修改');
                 return
            }

            const loading = this.$loading({
                text: "会员信息修改中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo=this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.MemberInfoModify",data).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error("会员信息修改失败："+d.ResponseHeader.ResultDesc);
                    return;
                }
                this.$message.success("会员信息修改完成");
                this.reload();
            }).catch((e)=>{
                loading.close();
                this.$message.error('会员信息修改失败：'+e);
                console.log('会员信息修改失败：'+e);
            })
        },
        empty(){
            this.memberInfo=null;
            this.birthday='';
            this.name="";
            this.sex="";
            this.oldCode.verifyCode="";
            this.newCode.mobile="";
            this.newCode.verifyCode="";
        }
    },
    beforeUnmount(){
       if(this.oldCode.time) clearInterval(this.oldCode.time);
       if(this.newCode.time) clearInterval(this.newCode.time);
    }
}
</script>

<style lang="scss">
@import "./memberInfoModify.scss";
</style>